<template>
    <v-app>
        <v-card>
        <v-card-text>
            <v-stepper
                v-model="currentStep"
            >
                <template >
                    <v-stepper-header>
                        <template v-for="(step,index) in steps">
                            <v-stepper-step
                            :key="(index+1)"
                            :complete="false"
                            :step="(index+1)"
                            color="#f95812"
                            :editable="validSteps[index] && index <= (currentStep-1)"
                            >
                            {{ step }}
                            </v-stepper-step>

                            <v-divider
                            v-if="index !== steps.length-1"
                            :key="index+'-divider'"
                            ></v-divider>
                        </template>
                    </v-stepper-header>

                    <v-stepper-items>
                        <v-stepper-content
                            v-for="(step,index) in steps"
                            :key="(index+1)"
                            :step="(index+1)"
                        >
                            <v-card>
                                <v-card-text>
                                    <v-form
                                        :ref="'form'+(index+1)"
                                        v-model="validSteps[index]"
                                        lazy-validation
                                        v-if="step == 'Seleccionar Tipo'"
                                    >
                                        <v-select
                                            v-model="order.orderType"
                                            :items="orderTypesList"
                                            :rules="[v => !!v || 'Debes elegir un tipo de pedido']"
                                            label="Tipo de Pedido"
                                            required
                                        ></v-select>
                                        <!--
                                        <v-autocomplete
                                            v-model="order.related"
                                            :loading="loadingSearch"
                                            :items="ordersList"
                                            :search-input.sync="searchOrder"
                                            cache-items
                                            :clearable="true"
                                            hide-no-data
                                            label="Pedido relacionado"
                                            solo-inverted
                                            @input="handleOrderRelated"
                                            required
                                            v-if="isOrdenDeDespacho"
                                        ></v-autocomplete>
                                        -->
                                        <v-combobox
                                            :items="ordersList"
                                            v-model="inputAutocomplete"
                                            label="Pedido relacionado"
                                            v-if="isOrdenDeDespacho"
                                        ></v-combobox>
                                    </v-form>
                                    <v-form
                                        :ref="'form'+(index+1)"
                                        v-model="validSteps[index]"
                                        lazy-validation
                                        v-if="step == 'Datos Cliente'"
                                    >
                                        <div class="row">
                                            <div class="col-md-6">
                                                <v-text-field
                                                    v-model="order.customer.name"
                                                    :counter="200"
                                                    :rules="[v => !!v || 'Debes completar este campo']"
                                                    label="Nombre cliente o Razón social"
                                                    required
                                                    prepend-icon="mdi-account"
                                                ></v-text-field>
                                            </div>
                                            <div class="col-md-6">
                                                <v-text-field
                                                    v-model="order.customer.surname"
                                                    :counter="200"
                                                    label="Apellido Cliente"
                                                    required
                                                    prepend-icon="mdi-account"
                                                ></v-text-field>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <v-text-field
                                                    v-model="order.customer.email"
                                                    :counter="200"
                                                    :rules="[v => !!v || 'Debes completar este campo']"
                                                    label="Email Cliente"
                                                    type="email"
                                                    required
                                                    prepend-icon="mdi-at"
                                                ></v-text-field>
                                            </div>
                                            <div class="col-md-6">
                                                <v-text-field
                                                    v-model="order.customer.street"
                                                    :counter="200"
                                                    :rules="[v => !!v || 'Debes completar este campo']"
                                                    label="Calle"
                                                    required
                                                    prepend-icon="mdi-map-marker"
                                                ></v-text-field>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-3">
                                                <v-text-field
                                                    v-model="order.customer.number"
                                                    :counter="200"
                                                    :rules="[v => !!v || 'Debes completar este campo']"
                                                    label="Número"
                                                    required
                                                    prepend-icon="mdi-pound"
                                                ></v-text-field>
                                            </div>
                                            <div class="col-md-3">
                                                <v-text-field
                                                    v-model="order.customer.dept"
                                                    :counter="200"
                                                    label="Depto."
                                                    prepend-icon="mdi-office-building"
                                                ></v-text-field>
                                            </div>
                                            <div class="col-md-3">
                                                <v-text-field v-model="order.customer.zip_code" :counter="16" label="Código postal" prepend-icon="mdi-email-outline" ></v-text-field>
                                            </div>
                                            <div class="col-md-3">
                                                <v-text-field
                                                    v-model="order.customer.additional"
                                                    :counter="200"
                                                    label="Información Adicional Dirección"
                                                    prepend-icon="mdi-information"
                                                ></v-text-field>
                                            </div>
                                        </div>

                                        <div class="row" v-if="store_country == 'CL'">
                                            <div class="col-md-6">
                                                <v-autocomplete
                                                    :items="comunas.map(e => e.region)"
                                                    v-model="order.customer.state"
                                                    label="Región"
                                                    :rules="[v => !!v || 'Este campo es requerido']"
                                                    prepend-icon="mdi-flag-triangle"
                                                    required
                                                ></v-autocomplete>
                                            </div>
                                            <div class="col-md-3">
                                                <v-autocomplete
                                                    :items="comunasList"
                                                    v-model="order.customer.province"
                                                    label="Comuna"
                                                    :disabled="!order.customer.state"
                                                    :rules="[v => !!v || 'Este campo es requerido']"
                                                    prepend-icon="mdi-city"
                                                    required
                                                ></v-autocomplete>
                                            </div>
                                            <div class="col-md-3">
                                                <v-text-field
                                                    v-model="order.customer.city"
                                                    :counter="200"
                                                    label="Ciudad"
                                                    prepend-icon="mdi-city"
                                                ></v-text-field>
                                            </div>
                                        </div>

                                        <div class="row" v-if="store_country == 'CRI'">
                                            <div class="col-md-6">
                                                <v-autocomplete
                                                    :items="comunas.map(e => e.region)"
                                                    v-model="order.customer.state"
                                                    label="Cantón"
                                                    :rules="[v => !!v || 'Este campo es requerido']"
                                                    prepend-icon="mdi-flag-triangle"
                                                    required
                                                ></v-autocomplete>
                                            </div>
                                            <div class="col-md-3">
                                                <v-autocomplete
                                                    :items="comunasList"
                                                    v-model="order.customer.province"
                                                    label="Distrito"
                                                    :disabled="!order.customer.state"
                                                    :rules="[v => !!v || 'Este campo es requerido']"
                                                    prepend-icon="mdi-city"
                                                    required
                                                ></v-autocomplete>
                                            </div>
                                            <div class="col-md-3">
                                                <v-text-field
                                                    v-model="order.customer.city"
                                                    :counter="200"
                                                    label="Provincia"
                                                    prepend-icon="mdi-city"
                                                ></v-text-field>
                                            </div>
                                        </div>

                                        <div class="row" v-if="store_country == 'PE'">
                                            <div class="col-md-4">
                                                <v-autocomplete
                                                    :items="comunas.map(e => e.ciudad)"
                                                    v-model="order.customer.city"
                                                    label="Departamento"
                                                    :rules="[v => !!v || 'Este campo es requerido']"
                                                    prepend-icon="mdi-flag-triangle"
                                                    required
                                                >
                                                </v-autocomplete>
                                            </div>
                                            <div class="col-md-4">
                                                <v-autocomplete
                                                    :items="geoStatesList"
                                                    v-model="order.customer.state"
                                                    label="Provincia"
                                                    :rules="[v => !!v || 'Este campo es requerido']"
                                                    prepend-icon="mdi-flag-triangle"
                                                    required
                                                >
                                                </v-autocomplete>
                                            </div>
                                            <div class="col-md-4">
                                                <v-autocomplete
                                                    :items="comunasList"
                                                    v-model="order.customer.province"
                                                    label="Distrito"
                                                    :disabled="!order.customer.state"
                                                    :rules="[v => !!v || 'Este campo es requerido']"
                                                    prepend-icon="mdi-city"
                                                    required
                                                >
                                                </v-autocomplete>
                                            </div>
                                            
                                        </div>



                                        <div class="row">
                                            <div class="col-md-6" v-if="store_country == 'CL'">
                                                <v-text-field
                                                    v-model="order.customer.dni"
                                                    :counter="200"
                                                    :rules="[v => !!v || 'Debes completar este campo']"
                                                    label="RUT"
                                                    required
                                                    @change="handleChangeDni"
                                                    @input="handleChangeDni"
                                                    prepend-icon="mdi-shield-account"
                                                ></v-text-field>
                                            </div>
                                            <div class="col-md-6" v-if="store_country == 'PE'">
                                                <v-text-field
                                                    v-model="order.customer.dni"
                                                    :counter="8"
                                                    :rules="[v => !!v || 'Debes completar este campo', v => ( v && v.length == 8 ) || 'El largo debe ser de 8 dígitos']"
                                                    label="DNI"
                                                    required
                                                    prepend-icon="mdi-shield-account"
                                                ></v-text-field>
                                            </div>
                                            <div class="col-md-6" v-if="store_country != 'PE' && store_country != 'CL'">
                                                <v-text-field
                                                    v-model="order.customer.dni"
                                                    :counter="200"
                                                    :rules="[v => !!v || 'Debes completar este campo', v => ( v && v.length <= 10 ) || 'El largo debe ser menor a 10 dígitos']"
                                                    label="DNI"
                                                    required
                                                    prepend-icon="mdi-shield-account"
                                                ></v-text-field>
                                            </div>
                                            <div class="col-md-6">
                                                <v-text-field
                                                    v-model="order.customer.phone"
                                                    :counter="200"
                                                    :rules="[v => !!v || 'Debes completar este campo']"
                                                    label="Teléfono"
                                                    required
                                                    prepend-icon="mdi-cellphone"
                                                ></v-text-field>
                                            </div>
                                        </div>

                                        <div  v-if="store_country == 'CL'">
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <v-checkbox label="Es empresa?" v-model="order.form_is_company"> Es empresa? </v-checkbox>
                                                </div>
                                            </div>
                                            <div v-if="order.form_is_company">
                                                <div class="row">
                                                    <div class="col-md-3" >
                                                        <v-text-field 
                                                            v-model="order.company_billing_data.company_name" label="Nombre Empresa" 
                                                            prepend-icon="mdi-office-building" :rules="[v => !!v || 'Debes completar este campo']" required
                                                        ></v-text-field>
                                                    </div>
                                                    <div class="col-md-3" v-if="store_country == 'CL'">
                                                        <v-text-field 
                                                            v-model="order.company_billing_data.company_activity" label="Giro Empresa" 
                                                            prepend-icon="mdi-office-building" :rules="[v => !!v || 'Debes completar este campo']" required
                                                        ></v-text-field>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <v-text-field 
                                                            v-model="order.company_billing_data.company_identification" label="RU/DNI Empresa" 
                                                            prepend-icon="mdi-shield-account" :rules="[v => !!v || 'Debes completar este campo']" required
                                                        ></v-text-field>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <v-text-field
                                                            v-model="order.company_billing_data.company_address" label="Direción Empresa" 
                                                            prepend-icon="mdi-map-marker" :rules="[v => !!v || 'Debes completar este campo']" required
                                                        ></v-text-field>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <v-text-field
                                                            v-model="order.company_billing_data.company_state" label="Región Empresa" 
                                                            prepend-icon="mdi-map-marker" :rules="[v => !!v || 'Debes completar este campo']" required
                                                        ></v-text-field>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <v-text-field
                                                            v-model="order.company_billing_data.company_province" label="Comuna Empresa" 
                                                            prepend-icon="mdi-map-marker" :rules="[v => !!v || 'Debes completar este campo']" required
                                                        ></v-text-field>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </v-form>
                                    <v-form
                                        :ref="'form'+(index+1)"
                                        v-model="validSteps[index]"
                                        lazy-validation
                                        v-if="step == 'Datos Pedido'"
                                    >
                                        <div class="row">
                                            <div class="col-md-4">
                                                <v-text-field
                                                    v-model="order.order_reference"
                                                    :counter="200"
                                                    label="Referencia"
                                                    required
                                                ></v-text-field>
                                            </div>
                                            <div class="col-md-4" v-if="false">
                                                <v-menu
                                                    ref="menu"
                                                    v-model="menu"
                                                    :close-on-content-click="false"
                                                    :return-value.sync="order.date"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="290px"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="order.date"
                                                        label="Fecha del pedido"
                                                        prepend-icon="event"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        :rules="[v => !!v || 'Debes completar este campo']"
                                                    ></v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="order.date" no-title scrollable>
                                                    <v-spacer></v-spacer>
                                                    <v-btn text color="primary" @click="menu = false">Cancelar</v-btn>
                                                    <v-btn text color="primary" @click="$refs.menu[0].save(order.date)">OK</v-btn>
                                                    </v-date-picker>
                                                </v-menu>
                                            </div>
                                            <div class="col-md-4" v-if="isOrdenDeDespacho">
                                                <!--
                                                <v-text-field
                                                    label="Pedido relacionado"
                                                    v-model="order.related"
                                                    :rules="[v => !!v || 'Debes completar este campo']"
                                                ></v-text-field>
                                                -->
                                                <v-autocomplete
                                                    v-model="order.related"
                                                    :loading="loadingSearch"
                                                    :items="ordersList"
                                                    :search-input.sync="searchOrder"
                                                    cache-items
                                                    :clearable="true"
                                                    hide-no-data
                                                    label="Pedido relacionado"
                                                    solo-inverted
                                                    @input="handleOrderRelated"
                                                    disabled
                                                ></v-autocomplete>
                                            </div>
                                            <div class="col-md-4" v-if="false">
                                                <v-menu
                                                    ref="menu2"
                                                    v-model="menu2"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    :return-value.sync="order.time"
                                                    transition="scale-transition"
                                                    offset-y
                                                    max-width="290px"
                                                    min-width="290px"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            v-model="order.time"
                                                            label="Hora"
                                                            prepend-icon="access_time"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            :rules="[v => !!v || 'Debes completar este campo']"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-time-picker
                                                    v-if="menu2"
                                                    v-model="order.time"
                                                    full-width
                                                    @click:minute="$refs.menu2[0].save(order.time)"
                                                    ></v-time-picker>
                                                </v-menu>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <v-combobox label="Productos" clearable :items="listProducts" v-model="selectProduct"></v-combobox>
                                            </div>
                                            <div class="col-md-2">
                                                <v-btn
                                                    color="success"
                                                    @click="addProduct"
                                                    :disabled="!selectProduct || !!$store.state.passport.loadingStack"
                                                    >
                                                    Añadir ->
                                                </v-btn>
                                            </div>
                                            <div class="col-md-6">
                                                <v-simple-table dense label="Productos">
                                                    <template v-slot:default>
                                                        <thead>
                                                            <tr>
                                                                <th v-for="(h,i) in ['Producto','SKU','EAN','Cantidad','Precio total']" :key="i" class="text-left">{{h}}</th>
                                                                <th class="text-right">Acciones</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(item, index) in order.items" :key="index">
                                                                <td>{{ item.name }}</td>
                                                                <td>{{ item.sku }}</td>
                                                                <td>{{ item.ean }}</td>
                                                                <td> <v-text-field type="number" v-model="item.quantity" step="1" min="1" dense ></v-text-field> </td>
                                                                <td> <v-text-field type="number" v-model="item.price" step="0.00" min="0.01" dense ></v-text-field> </td>
                                                                <td class="text-right">
                                                                    <v-btn @click="eliminarFila(item)" text icon color="red">
                                                                        <v-icon>mdi-close</v-icon>
                                                                    </v-btn>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <v-select label="Canal de venta" v-model="order.source" :items="sourcesList" :rules="[v => !!v || 'Debes completar este campo']">
                                                </v-select>
                                            </div>
                                            <div class="col-md-4">
                                                
                                                <template v-if="inputLoteConfig.length > 0">
                                                    <v-select label="Lote" v-model="order.lote" :items="inputLoteConfig" :rules="[v => !!v || 'Debes completar este campo']">
                                                    </v-select>
                                                </template>
                                                <template v-else>
                                                    <v-text-field
                                                    label="Lote"
                                                    v-model="order.lote"
                                                    ></v-text-field>
                                                </template>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <v-select label="Courier" v-model="order.carrier" :items="carriersList" >
                                                </v-select>
                                            </div>
                                            <div class="col-md-4">
                                                <v-text-field
                                                    label="Shipping number"
                                                    v-model="order.shipping_number"
                                                ></v-text-field>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <v-text-field label="Descuentos" suffix="$" required type="number" step="0.01" min="0.00" v-model="order.total_discount" ></v-text-field>
                                            </div>
                                            <div class="col-md-4">
                                                <v-text-field label="Costo de envío" suffix="$" required type="number" step="0.01" min="0.00" v-model="order.total_shipping" ></v-text-field>
                                                <!-- <input type="text" v-model="order.additional_data" /> -->
                                            </div>
                                        </div>
                                    </v-form>
                                </v-card-text>
                            </v-card>

                            <v-btn
                            class="btn-starkons-primary"
                            @click="nextStep(step)"
                            v-if="step !== steps[steps.length-1]"
                            :disabled="loadingOrderData || (isOrdenDeDespacho && !order.related)"
                            >
                            Siguiente
                            </v-btn>
                            <v-btn
                                class="btn-starkons-primary"
                                @click="nextStep(step)"
                                v-if="step === steps[steps.length-1]"
                                :disabled="disableCreateOrderBtn"
                            >
                                Crear orden
                            </v-btn>
                        </v-stepper-content>
                    </v-stepper-items>
                </template>
            </v-stepper>
        </v-card-text>
        </v-card>
    </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import comunas from "./comunas";
import formatearRut from './formatearRut';
export default {
    data(){
        return {
            currentStep: 1,
            steps: ['Seleccionar Tipo', 'Datos Cliente', 'Datos Pedido'],
            validSteps: [false,false,false],
            editable: true,
            form1Valid: false,
            orderTypesList: [],
            sourcesList: [],
            ordersList: [],
            menu: false,
            menu2: false,
            order:{
                orderType: undefined, // Tipo de Orden
                customer: {
                    name: undefined, // Nombre
                    surname: undefined, // Apellido
                    email: undefined, // Email
                    street: undefined, // Calle
                    number: undefined, // Numero
                    dept: undefined, // Depto.
                    state: undefined, // Region
                    province: undefined, // Comuna
                    dni: undefined, // RUT
                    phone: undefined, // Telefono
                    additional: undefined,
                    city: undefined,
                    zip_code: undefined
                },
                date: new Date().toISOString().substr(0, 10), // Fecha y hora
                time: null,
                items: [], // {sku, name, quantity, ean, price}
                order_reference: undefined, // Referencia *
                lote: 1, // lote * (1)
                related: undefined,// pedido relacionado *
                source: undefined, // canal de venta *
                carrier: undefined,
                total_discount: 0.0,
                total_shipping: 0.0,
                additional_data: undefined,
                company_billing_data:{
                    is_company: false,
                    company_name: undefined,
                    company_activity: undefined,
                    company_identification: undefined,
                    company_address: undefined,
                    company_state: undefined,
                    company_province: undefined,
                },
                form_is_company: false,
                additional_data_not_billing : undefined,
            },
            carriersList: [],
            comunas: [],
            listProducts: [],
            selectProduct: undefined,
            searchOrder: '',
            loadingSearch: false,
            loadingOrderData: false,
            itemsOrders: [],
            inputAutocomplete: null,
            inputLoteConfig: [],
            priceRules: [
                (v) => !!v || "El Campo es requerido",
                (v) => v > 0 || "Debes ingresar un valor mayor a 0",
            ],
            store_country : 'CL',
            disableCreateOrderBtn: false,
        }
    },
    methods: {
        nextStep (step) {
            let isValid = this.$refs['form'+(this.steps.indexOf(step)+1)][0].validate();
            if(isValid){
                if (step === this.steps[this.steps.length-1]) {
                    this.sendForm();
                } else {
                    this.currentStep = this.steps.indexOf(step)+2;
                }
            }else{
                this.$bvToast.toast("El Formulario no es válido", {
                    title: `Alerta`,
                    variant: 'warning',
                    solid: true,
                    toaster: 'b-toaster-bottom-center'
                });
            }
                
        },
        eliminarFila(item)
        {
            const index = this.order.items.indexOf(item);
            this.order.items.splice(index,1);
        },
        addProduct()
        {
            let vm = this;
            this.axios({
                url: 'order/products/'+this.selectProduct.value,
                method: 'GET'
            }).then(response => {
                let datos = response.data.product;
                let busqueda = vm.order.items.find(e => e.sku == datos.sku);
                if(busqueda){
                    busqueda.quantity++;
                    return;
                }
                vm.order.items.push({sku: datos.sku, ean: datos.ean, quantity: 1, name: datos.name, price: datos.price});
            }).catch(error => {
                console.log(error);
            });
        },
        fetchProducts()
        {
            let vm = this;
            this.axios({
                url: 'order/products_data_list',
                method: 'GET'
            }).then( response => {
                vm.listProducts = response.data;
            }).catch( error => {
                console.log(error);
            });
        },
        fetchSources()
        {
            let vm = this;
            this.axios({
                url: 'orders/sources/data_select_input',
                method: 'GET',
            }).then( response => {
                vm.sourcesList = response.data;
            }).catch( error => {
                console.log(error);
            })
        },
        fetchCarriers()
        {
            let vm = this;
            this.axios({
                url: 'shippings/carriers/get_data_list',
                method: 'GET',
            }).then( response => {
                vm.carriersList.push({text: 'Automático', value: null});
                let carriersMap = response.data.map( e => ({text: e.text, value: e.text}));
                carriersMap.forEach( e => { vm.carriersList.push(e) });
            }).catch( error => {
                console.log(error);
            })
        },
        fetchOrderTypes()
        {
            let vm = this;
            this.axios({
                url: 'orders/order_types/data_select_input',
                method: 'GET',
            }).then( response => {
                vm.orderTypesList = response.data;
            }).catch( error => {
                console.log(error);
            })
        },
        fetchLotes()
        {
            let vm = this;
            this.axios({
                url: 'inventory/lotes/data_select_input',
                method: 'GET',
            }).then( response => {
                vm.inputLoteConfig = response.data;
            }).catch( error => {
                console.log(error);
            })
        },
        sendForm(){
            let vm = this;
            vm.disableCreateOrderBtn = true;
            var additional_data_to = {};
            if (vm.order.form_is_company && (vm.store_country == 'CL' || vm.store_country == 'PE') ) {
                vm.order.company_billing_data.is_company = vm.order.form_is_company;
                console.log(vm.order.company_billing_data);
                Object.keys(vm.order.company_billing_data).forEach(function(key) {
                    additional_data_to[key]= vm.order.company_billing_data[key];
                });
            }
            if (typeof vm.order.additional_data_not_billing !== 'undefined') {
                console.log(vm.order.additional_data_not_billing)
                Object.keys(vm.order.additional_data_not_billing).forEach(function(key) {
                    additional_data_to[key]= vm.order.additional_data_not_billing[key];
                });
            }
            vm.order.additional_data = JSON.stringify(additional_data_to);

            this.axios({
                url: 'orders/create',
                method: 'POST',
                data: {
                    ...this.order,
                }
            }).then( response => {
                vm.disableCreateOrderBtn = false;
                vm.$router.push({
                    name: 'ordenes'
                });
            }).catch( error => {
                console.log(error);
                vm.disableCreateOrderBtn = false;
            });
        },
        handleChangeDni(){
            if(!!this.order.customer.dni && this.order.customer.dni.length > 1){
                this.order.customer.dni = formatearRut(this.order.customer.dni);
            }
        },
        //searchOrders(v){
        searchOrders(){
            this.loadingSearch = true;
            var vm = this;
            this.axios({
                url: 'orders/search_orders/all',
                method: 'GET',
                /*
                data: {
                    search: this.searchOrder
                }
                */
            }).then( response => {
                
                /*
                vm.items = response.data.filter(e => {
                    return (e.order_reference || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
                })
                */
                vm.ordersList = response.data;
            }).catch( error => {
                console.log(error);
            }).finally(() => {
                vm.loadingSearch = false;
            })
        },
        handleOrderRelated()
        {
            console.log('this.order.related', this.order.related);
            if(this.order.related){
                var vm = this;
                this.loadingOrderData = true;
                this.axios({
                    url: 'orders/ref/'+this.order.related
                }).then( response => {
                    vm.order.order_reference = vm.order.related+'RE';
                    vm.order.customer.name = response.data.customer.firstname;
                    vm.order.customer.surname = response.data.customer.lastname;
                    vm.order.customer.email = response.data.customer.email;
                    vm.order.customer.street = response.data.address.street;
                    vm.order.customer.number = response.data.address.number;
                    vm.order.customer.dept = response.data.address.dpto;
                    vm.order.customer.state = response.data.address.state;
                    vm.order.customer.province = response.data.address.province;
                    vm.order.customer.city = response.data.address.city;
                    vm.order.customer.dni = response.data.address.dni;
                    vm.order.customer.phone = response.data.address.phone;
                    vm.order.customer.zip_code = response.data.address.zip_code ?? '';
                    vm.order.customer.additional = response.data.address.additional ?? '';
                    vm.order.carrier = response.data.order.carrier;
                    vm.order.source = response.data.order.source;
                    vm.order.total_shipping = response.data.order.total_shipping;
                    vm.order.total_discount = response.data.order.total_discount;

                    
                    if (response.data.order.additional_data != null ) {
                        var additional_data_m = {};
                        Object.keys(response.data.order.additional_data).forEach(function(key) {
                            if (key != 'is_company' && key != 'company_name' && key != 'company_activity' && key != 'company_identification' && key != 'company_address' && key != 'company_state' && key != 'company_province') {
                                additional_data_m[key]= response.data.order.additional_data[key];
                            }
                        });
                        vm.order.additional_data_not_billing = additional_data_m;
                    }
                    
            
                    if ( vm.store_country == 'CL'                        
                    && response.data.order.additional_data != null 
                    && typeof response.data.order.additional_data.is_company !== 'undefined' 
                    && response.data.order.additional_data.is_company == "1"
                    && typeof response.data.order.additional_data.company_activity !== 'undefined' 
                    && typeof response.data.order.additional_data.company_name !== 'undefined' 
                    && typeof response.data.order.additional_data.company_identification !== 'undefined' 
                    && typeof response.data.order.additional_data.company_address !== 'undefined' 
                    && typeof response.data.order.additional_data.company_state !== 'undefined' 

                    ) {
                        vm.order.company_billing_data.is_company = true;
                        vm.order.company_billing_data.company_name = response.data.order.additional_data.company_name;
                        vm.order.company_billing_data.company_activity = response.data.order.additional_data.company_activity;
                        vm.order.company_billing_data.company_identification = response.data.order.additional_data.company_identification;
                        vm.order.company_billing_data.company_address = response.data.order.additional_data.company_address;
                        vm.order.company_billing_data.company_state = response.data.order.additional_data.company_state;
                        vm.order.company_billing_data.company_province = response.data.order.additional_data.company_province;
                        vm.order.form_is_company = true;
                    }

                    if ( vm.store_country == 'PE'                        
                    && response.data.order.additional_data != null 
                    && typeof response.data.order.additional_data.is_company !== 'undefined' 
                    && response.data.order.additional_data.is_company == "1"
                    && typeof response.data.order.additional_data.company_name !== 'undefined' 
                    && typeof response.data.order.additional_data.company_identification !== 'undefined' 
                    && typeof response.data.order.additional_data.company_address !== 'undefined' 
                    && typeof response.data.order.additional_data.company_state !== 'undefined' 

                    ) {
                        vm.order.company_billing_data.is_company = true;
                        vm.order.company_billing_data.company_name = response.data.order.additional_data.company_name;
                        vm.order.company_billing_data.company_identification = response.data.order.additional_data.company_identification;
                        vm.order.company_billing_data.company_address = response.data.order.additional_data.company_address;
                        vm.order.company_billing_data.company_state = response.data.order.additional_data.company_state;
                        vm.order.company_billing_data.company_province = response.data.order.additional_data.company_province;
                        vm.order.form_is_company = true;
                    }
                    
                    response.data.orderDetail.forEach( o => {
                        var price = o.price;
                        if ('total' in o) {
                            price = o.total;
                        }
                        vm.order.items.push({
                            sku: o.sku,
                            ean: o.ean,
                            quantity: o.quantity,
                            name: o.product,
                            price: price,
                        })
                    });
                    vm.loadingOrderData = false;
                }).catch( error => {
                    vm.loadingOrderData = false;
                });
                
            }
            else this.ordersList = [];
            
        }
    },
    mounted(){
        this.fetchOrderTypes();
        this.fetchSources();
        this.fetchProducts();
        this.searchOrders();
        this.fetchCarriers();
        this.fetchLotes();
    },
    created(){
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Dashboard Ordenes", route: "" },
            { title: "Nueva Orden ", route: "nueva" },
        ]);
        this.store_country = this.$store.state.passport.user.store_country;
        this.comunas = comunas.find(e => e.pais == this.$store.state.passport.user.store_country) ? comunas.find(e => e.pais == this.$store.state.passport.user.store_country).datos : [];
    },
    computed:{
        geoStatesList(){
            if(!this.order.customer.city || this.$store.state.passport.user.store_country != 'PE') return [];
            else{
                let list = [];
                let listGeoStates = [];
                let province = "";
                let region = undefined;
                let region_state = "";

                let geoCities = comunas.find(e => e.pais == this.$store.state.passport.user.store_country).datos.find(e => e.ciudad.toUpperCase() == this.order.customer.city.toUpperCase());
                if (geoCities) {
                    this.order.customer.city = geoCities.ciudad;
                }
                this.comunas.forEach( e => {
                    if(e.ciudad.toUpperCase() == this.order.customer.city.toUpperCase()){
                        listGeoStates.push(e.region);
                    }
                });
                if(this.order.customer.state){
                    region_state = listGeoStates.find(e => e.toUpperCase() == this.order.customer.state.toUpperCase());
                    if(region_state){
                        region = comunas.find(e => e.pais == this.$store.state.passport.user.store_country).datos.find(e => e.region.toUpperCase() == this.order.customer.state.toUpperCase());
                        region.comunas.forEach( e => { list.push(e); });
                        if(this.order.customer.province){
                            province = region.comunas.find(e => e.toUpperCase() == this.order.customer.province.toUpperCase());
                        }
                        region = region.region;
                    }
                    this.order.customer.state = region;
                }


                return listGeoStates;
            }
        },
        comunasList(){
            if(!this.order.customer.state) return [];
            else{
                let list = [];
                let region = comunas.find(e => e.pais == this.$store.state.passport.user.store_country).datos.find(e => e.region.toUpperCase() == this.order.customer.state.toUpperCase());
                let province = "";
                if(region){
                    region.comunas.forEach( e => { list.push(e); });
                    if(this.order.customer.province){
                        province = region.comunas.find(e => e.toUpperCase() == this.order.customer.province.toUpperCase());
                    }

                    this.order.customer.state = region.region;
                    this.order.customer.province = province;
                    return list;
                }else{
                    return [];
                }
                
            }
        },
        isOrdenDeDespacho(){
            if(!this.order.orderType) return false;
            else{
                let objOrderType = this.orderTypesList.find(e => e.value == this.order.orderType);
                return objOrderType.text == 'Orden de Redespacho';
            }
        }
    },
    watch: {
        searchOrder(val){
            if( val ){
                if( !this.ordersList.find(e => e.search(val) >= 0 ) ) this.searchOrders();
            }
            
        },
        inputAutocomplete(val){
            if(val){
                if(this.ordersList.find(e => e == val)){
                    this.order.related = val;
                    this.handleOrderRelated();
                }
                else{
                    this.order.related = undefined;
                }
            }else{
                this.order.related = undefined;
            }
        }
    }
}
</script>